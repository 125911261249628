<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1 p-relative">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          กระเป๋าสลากฯ
        </h3>
      </div>

      <div
        v-for="(item, index) in orderHis"
        :key="index"
      >
        <p
          v-if="findIndexByValue(checkround, '_id', item._id) >= 0"
          class="mb-0 text-primary"
        >
          งวดวันที่
          {{
            checkround[findIndexByValue(checkround, "_id", item._id)].roundth
          }}
        </p>
        <div
          class="card-list mt-1 border-0 mb-2"
          :class="item.status == 3 ? 'p-green' : 'p-light'"
        >
          <h3
            v-if="item.status == 3"
            class="bg-lot mr-1 text-white"
          >
            {{ item.lottonumber }}
          </h3>

          <h3
            v-else
            class="text-primary bg-lot mr-1"
          >
            {{ item.lottonumber }}
          </h3>
          <!-- <p class="small mb-0 text-primary">
            {{ item.count }}  ใบ
          </p> -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div>
                <p class="small mb-0 text-primary">
                  จำนวน {{ item.count }}  ใบ
                </p>
                <p class="small mb-0 text-primary">
                  วันที่ {{ item.roundth }}
                </p>

                <p
                  class="txt-order mb-0 mt-n50"
                  :class="item.status == 3 ? 'text-white' : 'text-muted'"
                >
                  หมายเลขออเดอร์ {{ item.order_id }}
                </p>
              </div>
            </div>
          </div>

          <button
            v-if="item.status === 3 && item.receive_reward === 0"
            class="posi-rig-cliam"
            @click="cliam(item._id)"
          >
            <small class="text-primary"> รับเงิน </small>
          </button>

          <div
            v-if="item.status === 3"
            class="posi-rig"
          >
            <small class="text-primary">
              เงินรางวัล
              {{
                item.reward_pay
                  ? Commas(Number(item.reward_pay).toFixed(2))
                  : 0.0
              }}
              <small class="text-white">₩</small>
            </small>
          </div>

          <div
            v-if="item.status == 2"
            class="reward-popup"
            style="background-color: purple; color: white"
          >
            รอผลรางวัล
          </div>

          <div
            v-if="item.status == 3"
            class="reward-popup cursor-pointer"
          >
            {{ item.reward_status }}
          </div>

          <div
            v-if="item.status == 4"
            class="reward-popup"
            style="background-color: white; color: #532a76"
          >
            ไม่ถูกรางวัล
          </div>
        </div>

        <!-- <div class="demo-spacing-0 d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @input="GetHis()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div> -->

        <div v-if="isActive === 'ago'">
          <p class="mb-0 text-primary">
            งวดวันที่ 1 ส.ค 2566
          </p>
          <div
            v-for="item in 4"
            :key="item"
            class="card-list mt-1 border-0 mb-2"
          >
            <p class="mb-25 text-primary">
              กองสลากสุวรรณภูมิ
            </p>

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <h3 class="text-primary mb-0 bg-lot mr-1">
                  345678
                </h3>

                <div>
                  <small class="mb-0 text-primary"> จำนวน </small>

                  <p class="mb-0 text-primary">
                    <span class="font-medium-4">5</span> ใบ
                  </p>
                </div>
              </div>

              <div class="ml-auto">
                <small class="mb-0 text-primary"> ค่าสลาก </small>

                <p class="mb-0 text-primary">
                  <span class="font-medium-4">15,022.13</span> ₩
                </p>
              </div>
            </div>
          </div>

          <div class="demo-spacing-0 d-flex justify-content-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>

      <div
        v-if="!orderHis.length"
        class="text-center mt-2 mb-4"
      >
        <img
          src="/icon/empty.png"
          alt="friend"
          height="75"
        >

        <p class="mb-0 text">
          ยังไม่มีลอตเตอรี่
        </p>
      </div>
      <ThisFooter />

      <b-modal
        ref="receice-reward"
        hide-footer
        hide-header
        centered
      >
        <div class="text-center">
          <h3 class="mb-0 text-primary mt-2">
            ขึ้นเงินรางวัลเรียบร้อยแล้วค่ะ
          </h3>

          <img
            src="@/assets/images/newIcon/cliam.png"
            alt="bag"
          >
          <div>
            <button
              class="btn-gray-txt p-75 w-50 my-2"
              @click="ReceiveReward(item._id)"
            >
              ยืนยัน
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
// import moment from 'moment-timezone'
// import ThisHeader from './components/ThisHeader.vue'
import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    BPagination,
    ThisFooter,
  },
  data() {
    return {
      checkround: [],
      orderHis: [],
      Loadmore: false,
      currentPage: 1,
      perPage: 5,
      rows: 1,
      isActive: 'last',
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      balance: 0,
      balance_withdraw: 0,
      balance_datecheck: '',
      HisDetail: {
        MyCart: [],
        total: 0,
      },
      reward: [],
      item: [],
      roundth: JSON.parse(localStorage.getItem('roundth')),
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
    if (JSON.parse(localStorage.getItem('HisLastRound'))) {
      this.HisDetail = JSON.parse(localStorage.getItem('HisLastRound'))
    }
  },
  mounted() {
    this.GetHis()
  },
  methods: {
    cliam(id) {
      this.$refs['receice-reward'].show()
      // eslint-disable-next-line no-underscore-dangle
      this.item = this.orderHis.find(item => item._id === id)
    },
    ReceiveReward(id) {
      const Obj = {
        id,
      }
      this.$http
        .post('/lotto-suvarn/ReceiveReward', Obj)
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          this.$refs['receice-reward'].hide()
          // this.Success(ress.data.message)
          this.GetHis()
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    GetHis() {
      this.checkround = []
      // this.Loadmore = true
      const data = {
        page: this.currentPage,
        perpage: this.perPage,
      }
      this.$http.post('lotto-suvarn/MyLottoHistory', data).then(ress => {
        this.Loadmore = false
        // console.log(ress.data)
        this.orderHis = ress.data.MyCart

        // eslint-disable-next-line array-callback-return
        this.orderHis.map(item => {
          // eslint-disable-next-line no-underscore-dangle
          if (
            this.findIndexByValue(this.checkround, 'roundth', item.roundth)
            === -1
          ) {
            this.checkround.push(item)
          }
        })
        // console.log(this.checkround)
        this.rows = ress.data.total
      })
    },
    findIndexByValue(array, property, value) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < array.length; i++) {
        if (array[i][property] === value) {
          return i
        }
      }
      return -1 // Return -1 if the value is not found
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ลงตะกร้าสำเร็จ</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
